<template>
  <div class="ap par">
    <div class="txt">
        <p>
          Наша клиника оказывает все виды лечения и протезирования зубов. <br>
          К каждому пациенту мы стараемся найти индивидуальный подход. <br>
          Для нас главное - сделать так, чтобы пациент остался доволен качеством работы. <br>
          Доступные цены, лечение без боли, вежливое отношение и отличное качество - главный приоритет нашей клиники! <br>
          Уютная и спокойная обстановка поможет вам забыть о страхе перед стоматологическими процедурами. <br>
          Посетив нас хотя бы раз, вам захочется возвращаться к нам снова за красивой улыбкой, здоровыми зубами и положительными эмоциями!
        </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.ap {
  min-height: 100vh;
  display: grid;
  place-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.par {
  background-image: url(../assets/fon1.png);
  background-size: cover;
}

.txt {
  width: 75vw;
  margin: auto;
}

p {
  font-size: 1.3vw;
  line-height: 3vw;
}

@media (max-width: 790px) {

  .par {
    background-image: none;
    background-color: #F0F0F0;
  }

  p {
    font-size: 14px;
    line-height: 30px;
  }

  .txt {
    width: 80vw;
    margin-top: 60px;
  }

}

</style>