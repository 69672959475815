<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="up">
    <Map />
  </div>
  <div class="cc">
    <div class="cont">
      <div class="mr"></div>
      <h1>
        Форма для отправки сообщений
      </h1>
      <form ref="form" @submit.prevent="sendEmail">    
        <input 
          required
          type="text" 
          placeholder="Имя"
          name="user_name"
        >
        <input 
          required
          type="number" 
          placeholder="Телефон"
          name="user_phone"
        >
        <textarea 
          type="text" 
          placeholder="Добавьте сообщение..."
          name="message"
        ></textarea>
        <input 
          class="check"
          type="checkbox" 
          id="sog" 
          value="sog" 
          name="checkedSog" 
        />
        <label 
          for="sog"
        >
          Соглашаюсь на обработку моих персональных данных
        </label>
        <div class="bt">
        <input 
          class="bts"
          type="submit" 
          value="Отправить"
        >
        </div>
        <div class="vis" 
          v-show="visible"
        >
        <p>
          Ваше сообщение успешно отправлено! <br>
          Скоро мы Вам перезвоним.
        </p> 
        </div>  
      </form>
    </div>
  </div>
  <div class="fmr">
    <Foot />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'
import emailjs from '@emailjs/browser';

export default {

name: 'ContactsView',
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
  },
  data: () => ({     
    visible: false,   
  }),
  methods: {
    sendEmail(event) {
    emailjs.sendForm('service_n4vzwxo', 'template_u2cjc6a', this.$refs.form, '115yfGhv-PVucYBOw')
      .then((result) => {
      console.log('SUCCESS!', result.text);
      }, (error) => {
      console.log('FAILED...', error.text);
      });
      event.target.reset();
      this.visible = true;
      setTimeout(() => this.visible = false, 10000);
    }
  }
}
</script>

<style scoped>

.up {
  margin-top: -7.5vh;
}

.cc {
  background-color: #f5ee92;
  height: 80vh;
}

.mr {
  height: 5vh;
}

.cont {
  background-color: #f5ee92;
  width: 35vw;
  margin: 0 auto;
}

h1 {
  font-size: 2vw;
}

input {
  margin-top: 10px;
  border: #bb8745;
  width: 100%;
  height: 50px;
  padding: 20px;
}

textarea {
  margin-top: 10px;
  border: #bb8745;
  width: 100%;
  height: 100px;
  padding: 20px;
}

.check {
  width: 20px;
  margin: 0 0 0 0;
}

label {
  vertical-align: top;
  margin: 15px 20px;
  color: #333;
  font-size: 14px;
}

.bts {
  background: #bb8745;
  border: #bb8745;
  color: #fff;
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: 0;
  padding: 0;
}

.vis {
  margin-top: 20px;
  font-size: 1.2vw;
  color: green;
}

.btn {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

@media (max-width: 790px) {

  .up {
    margin-top: 0;
  }

  .cc {
    margin-top: 20vh;
    min-height: 600px;
  }

  h1 {
    font-size: 20px;
  }

  .cont {
    width: 85vw;
  }

  label {  
    margin: -40px 40px;
  }

  .vis {
    font-size: 17px;
  }

  .fmr {
    margin-top: -20vh;
  }

}

</style>