<template>
    <div class="npa">
        <div class="cen">
            <div class="mr"></div>
            <router-link class="lin" to="/lic">
                <h6>
                    Лицензия
                </h6>
            </router-link>
            <router-link class="lin" to="/sez">
                <h6>
                    Санитарно-эпидемиологическое заключение
                </h6>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.npa {
    background-color: #f5ee92;
    height: 70vh;
}

.cen {
    width: 80vw;
    margin: 10vh auto;
}

.mr {
    height: 20vh;
}

h6 {
    font-size: 1.7vw;
    cursor: pointer;
    transition: all 0.4s ease;
    margin-bottom: 5vh;
}

h6:hover {
    color: #bb8745;
    transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .npa {
        height: 40vh;
    }

    .cen {
        width: 90vw;
    }

    .mr {
        height: 10vh;
    }

    h6 {
        font-size: 20px;
    }

}

</style>