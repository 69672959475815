<template>
  <div class="modal" id="exampleModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Заполните пожалуйста форму</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <form ref="form" @submit.prevent="sendEmail">    
            <input 
              required
              type="text" 
              placeholder="Имя"
              name="user_name"
            >
            <input 
              required
              type="number" 
              placeholder="Телефон"
              name="user_phone"
            >
            <textarea 
              type="text" 
              placeholder="Добавьте сообщение..."
              name="message"
            ></textarea>
            <input 
              class="check"
              type="checkbox" 
              id="sog" 
              value="sog" 
              name="checkedSog" 
            />
            <label 
              for="sog"
            >
              Соглашаюсь на обработку моих персональных данных
            </label>
            <div class="bt">
              <input 
                class="bts"
                type="submit" 
                value="Отправить"
              >
            </div>
            <div class="vis" 
              v-show="visible"
            >
              <p>
                Ваше сообщение успешно отправлено! <br>
                Скоро мы Вам перезвоним.
              </p> 
              <button 
                type="button" 
                class="btn btn-outline-light" 
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
            </div>  
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "Modal",
  data: () => ({     
    visible: false,   
  }),
  methods: {
    sendEmail(event) {
      emailjs.sendForm('service_n4vzwxo', 'template_u2cjc6a', this.$refs.form, '115yfGhv-PVucYBOw')
        .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
        event.target.reset();
        this.visible = true;
        setTimeout(() => this.visible = false, 10000);
    }
  }
}
</script>

<style scoped>

.modal-content {
  background-color: #6c9f67;
  min-height: 600px;
  margin-top: 10px;
}

.modal-title {
  color: #FFF;
}

.btn-close {
  background: none;
  opacity: 1;
  display: flex;
  align-items: center;
}

input {
  margin-top: 10px;
  border: #bb8745;
  width: 100%;
  height: 50px;
  padding: 20px;
}

textarea {
  margin-top: 10px;
  border: #bb8745;
  width: 100%;
  height: 100px;
  padding: 20px;
}

.check {
  width: 20px;
  margin: 0 0 0 0;
}

label {
  vertical-align: top;
  margin: 15px 20px;
  color: #fff;
  font-size: 14px;
}

.bts {
  background: yellow;
  border: yellow;
  color: #6c9f67;
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: 0;
  padding: 0;
}

.vis {
  margin-top: 20px;
  font-size: 17px;
  color: rgb(4, 250, 4);
}

.btn {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

p {
  color: rgb(4, 250, 4);
  text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
  margin-top: -10px;
}

@media (max-width: 790px) {

  label {
    vertical-align: top;
    margin: -40px 30px;
    color: #fff;
  }

}

</style>