<template>
  <div class="foot">
    <img class='ii' alt="" src="../assets/foo.jpg" />
    <!-- <h1>
      ДЕНТАКОР ПЛЮС
    </h1>
    <div class="kuv">
      <p>
        Альгешево
      </p>
    </div>
    <h2>
      Стоматология
    </h2> -->
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

/* .foot { */
  /* background-color: #6c9f67; */
  /* height: 85vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
/* } */

/* h1 {
  border: 2px solid white; 
  padding: 5vw;
  font-size: 3vw;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  color: white;
  font-weight: 200;
  margin-top: 5vh;
}

.kuv {
  width: 8vw;
  height: 4vh;
  margin-top: 19vh;
  margin-left: 23vw;
  z-index: 5;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
}

p {
  font-family: cursive, sans-serif;
  margin-top: 0;
  font-size: 1.3vw;
  color: white;
}

h2 {
  font-size: 2vw;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  color: white;
  font-weight: 200;
  margin-top: 4vh;
} */

img {
  width: 100%;
}


@media (max-width: 790px) {

  .foot {
    /* height: 20vh; */
    margin-top: 20vh;
  }

  /* h1 {
    border: 1px solid white; 
    font-size: 30px;
  }

  .kuv {
    width: 25vw;
    margin-top: 7.3vh;
    margin-left: 36vw;
  }

  p {
    margin-top: 0;
    font-size: 15px;
  }

  h2 {
    font-size: 30px;
    margin-top: 0vh;
  } */

}

</style>