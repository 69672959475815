<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="pri">
    <div class="mr"></div>
        <h1>Цены</h1>
        <div class="pp">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr class="table-primary">
                        <th scope="col"></th>
                        <th scope="col">Услуги</th>
                        <th scope="col">Цены, руб</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>
                          Осмотр и консультация врача-стоматолога
                        </td>
                        <td>бесплатно</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Лечение кариеса</td>
                        <td>от 1 700</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <!-- <td colspan="2">Larry the Bird</td> -->
                        <td>Профессиональная гигиена</td>
                        <td>2 000</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Металлокерамическая коронка</td>
                        <td>от 4 000</td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Безметалловая керамика</td>
                        <td>от 9 000</td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>Съемный протез</td>
                        <td>от 10 000</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <p>
            В этом месте может размещаться пояснительный текст к прайсу.
        </p> -->
    </div>
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'PriceView',
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
  }
}
</script>

<style scoped>

.pri {
  background-color: #F7E0D6;
  height: 100vh;
  margin-top: -7.5vh;
}

.mr {
  height: 5vh;
}

h1 {
  font-size: 3vw;
  margin-left: 5vw;
}

.pp {
  width: 88.5vw;
  margin: 0 auto;
}

p {
  margin-left: 5vw;
  margin-top: 5vh;
  font-size: 1.2vw;
}

th,
td {
  font-size: 1.5vw;
}

@media (max-width: 790px) {

  .pri {
    height: 100vh;
    margin-top: 0;
  }

  h1 {
    font-size: 30px;
  }

  th,
  td {
    font-size: 17px;
  }

  p {
    font-size: 17px;
  }

}

</style>

