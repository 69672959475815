<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="home">
    <div class="left">
      <img class='ii hi' alt="" src="../assets/dkp.jpg" />
      <!-- <h1>
        ДЕНТАКОР ПЛЮС
      </h1>
      <div class="kuv">
        <p>
          Альгешево
        </p>
      </div>
      <h2>
        Стоматология
      </h2> -->
      <a 
        href="tel:+78352373651"
      >
        <h3 class="animate__animated animate__zoomIn">
          +7 (8352) 37-36-51
        </h3>
      </a> 
    </div>
    <div class="right">
      <a-carousel autoplay effect="fade">
        <div><img class='ii' alt="" src="../assets/dentakor.jpg" /></div>
        <!-- <div><img class='ii' alt="" src="../assets/alfa2.jpg" /></div> -->
      </a-carousel>
    </div>
  </div>
  <Text1 />
  <Resep />
  <Otz />
  <Doc />
  <Servis />
  <Npa />
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Text1 from '@/components/Text1.vue'
import Resep from '@/components/Resep.vue'
import Otz from '@/components/Otz.vue'
import Doc from '@/components/Doc.vue'
import Servis from '@/components/Servis.vue'
import Npa from '@/components/Npa.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'
import { Carousel } from 'ant-design-vue';

export default {
  name: 'HomeView',  
  components: {
    Menu,
    Zapis,
    Phone,
    Text1,
    Resep,
    Otz,
    Doc,
    Servis,
    Npa,
    Map,
    Foot,
    ACarousel: Carousel,
  }
}
</script>

<style scoped>

.home {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
  margin: -7.5vh auto;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 107.5vh;
  margin-top: -3.8vh;
}

/* h1 {
  border: 3px solid black; 
  padding: 5vw;
  font-size: 4vw;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  color: #151515;
  font-weight: 300;
}

p {
  font-family: cursive, sans-serif;
  margin-top: 0;
  font-size: 1.5vw;
}

.kuv {
  width: 9vw;
  height: 4vh;
  margin-top: -14vh;
  margin-left: 30vw;
  z-index: 5;
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
}

h2 {
  font-size: 5vw;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  color: #151515;
  font-weight: 300;
  margin-top: 7vh;
} */

h3 {
  font-size: 4vw;
  font-family: Lato, sans-serif;
  color: yellow;
  font-weight: bold;
  margin-top: -30vh;
  text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
}

.right {
  height: 100vh;
}

.ii {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 100vh;
  /* line-height: 160px; */
  overflow: hidden;
}

/* .ant-carousel :deep(.slick-slide h3) {
  color: #fff;
} */

@media (max-width: 790px) {

  .home {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin-top: 0;
  }

  .left {
    height: 45vh;
  }

  /* h1 {
    border: 1px solid black; 
    font-size: 25px;
    margin-top: 20px;
    margin-right: 65px;
  }

  p {
    font-size: 14px;
  }

  .kuv {
    width: 22vw;
    height: 4vh;
    margin-top: 1vh;
    margin-left: 14vw;
  }

  h2 {
    font-size: 25px;
    margin-top: 0;
    margin-right: 65px;
  } */

  .hi {
    margin-top: 2.7vh;
  }

  h3 {
    font-size: 25px;
    margin-right: 35px;
    margin-top: -11vh;
  }

  .right {
    height: 40vh;
    margin-top: -2vh;
  }

  .ii {
    height: 40vh;
  }

  .ant-carousel :deep(.slick-slide) {
    height: 40vh;
  }

}

</style>
