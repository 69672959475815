<template>
    <div class="map">
        <div class="lef">
            <div class="con">
                <h5>
                    г. Чебоксары, ул. Болгарстроя, 9/11
                </h5>
                <a 
                    href="tel:+78352373651"
                >
                    <h5 class="tel">
                        +7 (8352) 37-36-51
                    </h5>
                </a> 
                <a 
                    href="mailto:denta-kor2014@mail.ru"
                >
                    <h5 class="mp">
                        denta-kor2014@mail.ru
                    </h5>
                </a>
                <a href="https://vk.com/dentakorplus" target="_blank" rel="noopener">
                    <img class='vk' alt="" src="../assets/vk.jpg"/>
                </a>
                <h5>
                    Режим работы: <br>
                    ПН-ПТ с 9:00 до 19:00 <br>
                    СБ с 10:00 до 14:00 <br>
                    ВС - выходной
                </h5>
            </div>
        </div>
        <div class="rig">
            <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/dentakor_plyus/1275137812/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">ДентаКор Плюс</a><a href="https://yandex.ru/maps/45/cheboksary/category/dental_clinics/184106132/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Стоматологическая клиника в Чебоксарах</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.281415%2C56.069831&mode=search&oid=1275137812&ol=biz&z=16.37" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .map {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
        margin: 0 auto;
        height: 80vh;
        background-color: #bb8745;
    }

    .con {
        width: 30vw;
        margin: 12vh auto;
    }

    h5 {
        margin-top: 5vh;
        font-size: 1.5vw;
        color: white;
        transition: all 0.4s ease;
    }

    .tel:hover {
        color: #333;
        transition: all 0.4s ease;
    }

    .mp:hover {
        color: blue;
        transition: all 0.4s ease;
    }

    .vk {
        width: 3vw;
        transition: all 0.4s ease;
        margin-top: 2.5vh;
    }

    .vk:hover {
        transform: scale(1.1);
        transition: all 0.4s ease;
    }

    iframe {
        width: 100%;
        height: 80vh;
    }

    @media (max-width: 790px) {

        .map {
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            height: 119vh;
        }

        .con {
            width: 90vw;
            margin: 3vh;
        }

        h5 {
            margin-top: 3vh;
            font-size: 17px;
        }

        .vk {
            width: 50px;
            margin-top: 7px;
        }

        iframe {
            height: 90vh;
        }

    }

</style>