<template>
    <div class="otz">
        <div class="left">
            <div data-aos="zoom-in" data-aos-delay="200">
                <div class="sf1"></div>
            </div>
            <h3>
                Качество
            </h3>
            <p>
                «Я уже много лет лечу зубы у Корниловой Эльмиры. Очень нравится этот врач, делает всё аккуратно, без боли. Всем рекомендую лечить зубы только здесь. Цены очень хорошие вотличии от других клиник».
            </p>
            <h4>
                Наталья Пименова
            </h4>
        </div>
        <div class="cent">
            <div data-aos="zoom-in" data-aos-delay="700">
                <div class="sf2"></div>
            </div>
            <h3>
                Позитив
            </h3>
            <p>
                «Делала здесь чистку, понравился подход стоматолога, из за моей чувствительности сделала анестезию. Понравился подход, есть желание вернуться вновь. Советую, удобно рядом с домом».
            </p>
            <h4>
                Алёна Барышова
            </h4>
        </div>
        <div class="right">
            <div data-aos="zoom-in" data-aos-delay="1200">
                <div class="sf3"></div>
            </div>
            <h3>
                Профессионализм
            </h3>
            <p>
                «Вылечила зубы, поставила 2 пломбы, вставила 2 зуба. Быстро и недорого!».
            </p>
            <h4>
                Ирина Ершова
            </h4>
        </div>
    </div>
    <button 
        type="button" 
        data-bs-toggle="modal" 
        data-bs-target="#exampleModal"
    >
        <div class="nap"></div>
        <h5>
            Оставить свой отзыв
        </h5>
    </button>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: 'Otz',
    components: {
        Modal,
    }
}
</script>

<style scoped>

.otz {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin: 0 auto;
    height: 100vh;
}

.left,
.cent,
.right {
    display: flex;
    flex-direction: column;
    width: 25vw;
    margin: 0 auto;
    align-items: flex-start;
}

h3 {
    margin-top: -45vh;
    font-size: 2vw;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    color: #151515;
    font-weight: 300;
    z-index: 5;
}

p {
    font-size: 1.2vw;
    line-height: 3.5vh;
    z-index: 5;
}

h4 {
    font-size: 1.7vw;
    z-index: 2;
}

.sf1 {
    background-color: gray;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    opacity: 0.3;
    margin-top: 15vh;
}

.sf2 {
    background-color: #bb8745;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    opacity: 0.3;
    margin-top: 15vh;
}

.sf3 {
    background-color: yellow;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    opacity: 0.3;
    margin-top: 15vh;
}

.nap {
    background-color: #6c9f67;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    transition: all 0.4s ease;
    margin-top: -15vh;
}

h5 {
    font-size: 1vw;
    margin-left: 1vw;
    margin-top: -14vh;
    transition: all 0.4s ease;
}

.nap:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
    background-color: #bb8745;
}

h5:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
    color: #6c9f67;
}

button {
    display: flex;
    width: 15vw;
    margin-left: 10vw;
    background: none;
    border: none;
    margin-bottom: 10vh;
}

@media (max-width: 790px) {

    .otz {
        height: 180vh;
        margin-top: 80px;
    }

    .left {
        width: 70vw;
    }

    .cent {
        width: 70vw;
        margin-top: -25vh;
    }

    .right {
        width: 70vw;
        margin-top: -25vh;
    }

    .sf1,
    .sf2,
    .sf3 {
        width: 70vw;
        height: 70vw;
    }

    h3 {
        margin-top: 0vh;
        font-size: 25px;
        margin-top: -30vh;
    }

    p {
        font-size: 14px;
        line-height: 25px;
    }

    h4 {
        font-size: 19px;
    }

    h5 {
        font-size: 14px;
        margin-left: 3vw;
    }

    button {
        width: 60vw;
        margin-top: 15vh;
        margin-bottom: 0;
    }

    .nap {
        width: 8vw;
        height: 8vw;
    }

}

</style>