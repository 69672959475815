<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="usl">
    <div class="lef">
      <router-link to="/hygiene">
        <div class="bloc">
          <img alt="" src="../assets/z1.png">
        </div>
        <button class="sname">
          Профилактическая гигиена
        </button>
      </router-link> 
    </div>
    <div class="cen">
      <router-link to="/therapy">
        <div class="bloc">
          <img alt="" src="../assets/z3.png">
        </div>
        <button class="sname">
          Терапия
        </button>
      </router-link>
    </div>
    <div class="rig">
      <router-link to="/orthopedics">
        <div class="bloc">
          <img alt="" src="../assets/z2.png">
        </div>
        <button class="sname">
          Ортопедия
        </button>
      </router-link>
    </div>

    <!-- <div class="lef">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z4.png">
        </div>
        <button class="sname">
          Удаление белых пятен
        </button>
      </router-link>
    </div>
    <div class="cen">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z5.png">
        </div>
        <button class="sname">
          Виниры
        </button>
      </router-link>
    </div>
    <div class="rig">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z6.png">
        </div>
        <button class="sname">
          Пломбы
        </button>
      </router-link>
    </div>

    <div class="lef">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z7.png">
        </div>
        <button class="sname">
          Удаление зубов мудрости
        </button>
      </router-link>
    </div>
    <div class="cen">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z8.png">
        </div>
        <button class="sname">
          Стоматологическая неотложная помощь
        </button>
      </router-link>
    </div>
    <div class="rig">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z9.png">
        </div>
        <button class="sname">
          Стоматологическая чистка
        </button>
      </router-link>
    </div>

    <div class="lef">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z10.png">
        </div>
        <button class="sname">
          Зубные импланты
        </button>
      </router-link>
    </div>
    <div class="cen">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z11.png">
        </div>
        <button class="sname">
          Зубные коронки
        </button>
      </router-link>
    </div>
    <div class="rig">
      <router-link to="/any">
        <div class="bloc">
          <img alt="" src="../assets/z12.png">
        </div>
        <button class="sname">
          Хирургия
        </button>
      </router-link>
    </div> -->
  </div>
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
  }
}
</script>

<style scoped>

.usl {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: repeat(3, 42vh);
  margin: 0 auto;
  width: 80vw;
  height: 70vh;
}

.bloc {
  background-color: #f5ee92;
  width: 25vw;
  height: 30vh;
  margin: 5vh auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: 10vw;
  height: 10vw;
}

.sname {
  background-color: #363636;
  color: white;
  font-size: 1.5vw;
  width: 25vw;
  height: 8vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #363636; 
  transition: all 0.4s ease;
}

.sname:hover {
  background-color: white;
  color: black;
  transition: all 0.4s ease;
}

button {
  display: flex;
  width: 20vw;
  margin-left: 10vw;
  background: none;
  border: none;
  margin-bottom: 0;
  line-height: 1.5vw;
}

@media (max-width: 790px) {

  .usl {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: repeat(3, 42vh);
    height: 135vh;
  }

  .bloc {
    width: 80vw;
    height: 30vh;
    margin-top: 6vh;
  }

  img {
    width: 40vw;
    height: 40vw;
  }

  .sname {
    font-size: 20px;
    width: 80vw;
    height: 8vh;
    margin-top: 0;
    line-height: 21px;
  }

}

</style>

