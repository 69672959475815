<template>
  <Menu />
  <Zapis />
  <Phone />
  <Doc />
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Doc from '@/components/Doc.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'DocView',
  components: {
    Menu,
    Zapis,
    Phone,
    Doc,
    Map,
    Foot,
  }
}
</script>

<style scoped>



@media (max-width: 790px) {

 

}

</style>

