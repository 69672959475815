<template>
    <div class="nap">
        <button 
            type="button" 
            data-bs-toggle="modal" 
            data-bs-target="#exampleModal"
        >
            <h3>
                Запись
            </h3>
        </button>
    </div>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    components: {
        Modal,
    }
}
</script>

<style scoped>

.nap {
    position: fixed;
    margin-top: 20vh;
    margin-left: 91vw;
    background-color: #6c9f67;
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    z-index: 10;
}

.nap:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
}

h3 {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2vw;
    margin-top: 1.3vh;
    text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
}

button {
    background: none;
    border: none;
}

@media (max-width: 790px) {

    .nap { 
        width: 15vw;
        height: 15vw;
        margin-top: 11vh;
        margin-left: 82vw;
        border: 1px solid white;
    }

    h3 {
        font-size: 12px;
    }

}

</style>