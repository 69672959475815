<template>
    <div class="ser">
        <div class="lef">
            <router-link to="/hygiene">
                <div class="bloc">
                    <img alt="" src="../assets/z1.png">
                </div>
                <button class="sname">
                    Профилактическая гигиена
                </button>
            </router-link>
        </div>
        <div class="cen">
            <router-link to="/therapy">
                <div class="bloc">
                    <img alt="" src="../assets/z3.png">
                </div>
                <button class="sname">
                    Терапия
                </button>
            </router-link>
        </div>
        <div class="rig">
            <router-link to="/orthopedics">
                <div class="bloc">
                    <img alt="" src="../assets/z2.png">
                </div>
                <button class="sname">
                    Ортопедия
                </button>
            </router-link>
        </div>
    </div>
    <!-- <button>
        <div class="nap"></div>
        <router-link class="link" to="/servis">
            <h5>
                Перейти к остальным услугам
            </h5>
        </router-link>
    </button> -->
</template>

<script>
export default {

}
</script>

<style scoped>

.ser {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin: 0 auto;
    width: 80vw;
    height: 70vh;
}

.bloc {
    background-color: #F7E0D6;
    width: 25vw;
    height: 30vh;
    margin: 16vh auto;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    width: 10vw;
    height: 10vw;
}

.sname {
    background-color: #363636;
    color: white;
    font-size: 1.5vw;
    width: 25vw;
    height: 8vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #363636; 
    transition: all 0.4s ease;
}

.sname:hover {
    background-color: white;
    color: black;
    transition: all 0.4s ease;
}

.nap {
    background-color: black;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    transition: all 0.4s ease;
}

.link {
    text-decoration: none;
    color: #000;
}

h5 {
    font-size: 1vw;
    margin-left: 1vw;
    margin-top: 1vh;
    transition: all 0.4s ease;
}

.nap:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
    background-color: #bb8745;
}

h5:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
    color: #bb8745;
}

button {
    display: flex;
    width: 20vw;
    margin-left: 10vw;
    background: none;
    border: none;
    margin-bottom: 0;
}

@media (max-width: 790px) {

    .ser {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        height: 140vh;
        margin-top: 0;
    }

    .bloc {
        width: 80vw;
        height: 30vh;
        margin-top: 0;
    }

    img {
        width: 40vw;
        height: 40vw;
    }

    .sname {
        font-size: 20px;
        width: 80vw;
        height: 8vh;
        margin-top: 0;
    }

    .lef {
        margin-top: 12vh;
        margin-bottom: 0;
    }

    .cen {
        margin-top: 0vh;
        margin-bottom: 0;
    }

    .rig {
        margin-top: 0vh;
        margin-bottom: 3vh;
    }

    .nap {
        background-color: black;
        width: 8vw;
        height: 8vw;
    }

    button {
        width: 80vw;
    }

    h5 {
        font-size: 14px;
        margin-left: 3vw;
    }

}

</style>