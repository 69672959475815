<template>
    <div class="doc">
        <div class="row row-cols-1 row-cols-md-3 g-3">
            <div class="col">
                <router-link class="lin" to="/kem">
                    <div class="card h-100">
                        <img src="../assets/doc11.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                        <h5 class="card-title">Корнилова Эльмира Мяксутовна</h5>
                        <p class="card-text">
                            врач стоматолог-терапевт
                        </p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link class="lin" to="/ksv">
                    <div class="card h-100">
                        <img src="../assets/doc22.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                        <h5 class="card-title">Корнилов Сергей Васильевич</h5>
                        <p class="card-text">
                            врач стоматолог-ортопед
                        </p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link class="lin" to="/meo">
                    <div class="card h-100">
                        <img src="../assets/wum.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                        <h5 class="card-title">Мартынова Елена Олеговна</h5>
                        <p class="card-text">
                            врач стоматолог-терапевт
                        </p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Doc',
    components: {
        
    }
}
</script>

<style scoped>

.doc {
    height: 138vh;
    background-color: rgb(230, 229, 229);
    margin-top: -11vh;
}

.row {
    max-width: 90%;
    margin: 0 auto;
}

.col {
    margin-top: 16vh;
}

.card-title {
    color: black;
    text-decoration: none;
    font-size: 1.5vw;
}

.card {
    transition: all 0.1s ease;
}

.card:hover {
    transform: scale(1.01);
    box-shadow: 2px 2px 10px 10px rgba(138, 137, 137, 0.7);
    transition: all 0.1s ease;
}

@media (max-width: 790px) {

    .doc {
        height: 299vh;
        margin-top: 0;
    }

    .row {
        max-width: 100%;
    }

    .col {
        margin-top: 2vh;
    }

    .card-title {
        font-size: 19px;
    }

}

</style>