<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="pri">
    <img class='ii' alt="" src="../assets/sez1.jpg" />
    <img class='ii' alt="" src="../assets/sez2.jpg" />
  </div>
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'PriceView',
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
  }
}
</script>

<style scoped>

.pri {
  background-color: #f5ee92;
  height: 440vh;
  margin-top: -7.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ii {
  width: 70%;
  overflow: hidden;
  object-fit: cover;
  margin: auto;
}

@media (max-width: 790px) {

  .pri {
    height: 150vh;
    margin-top: 0;
  }

  .ii {
    width: 90%;
  }

}

</style>

