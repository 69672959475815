<template>  
  <a @click="$router.go(-1)">
    <button class="bm">
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
      </svg>
    </button>
  </a>   
  <div class="cov">
    <div class="cen">
      <nav>
        <router-link to="/">Главная</router-link> 
        <router-link to="/servis">Стоматологические услуги</router-link>
        <router-link to="/doc">Наши доктора</router-link>
        <router-link to="/price">Цены</router-link>
        <router-link to="/contacts">Контакты</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.cen {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-image: url(../assets/fon2.png);
  background-size: cover;
  background-position-y: center;
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

nav a {
  font-weight: bold;
  color: #fff;
  margin-bottom: 3vh;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease;
  font-family: Lato, sans-serif;
  font-size: 1.7vw;
}

a:hover {
  color: yellow;
  transition: all 0.4s ease;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bi {
  background: #6c9f67;
  width: 3vw;
  height: 3vw;
  transition: all 0.4s ease;
  cursor: pointer;
}

.bi:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.bm {
  background: #6c9f67;
  border: none;
  position: absolute;
  margin-top: 8vh;
  margin-left: 92vw;
}

@media (max-width: 790px) {

  .cen {
    background-position-x: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cov {
    background-color: #6c9f67;
  }

  nav a {
    font-size: 20px;
  }

  .bi {
    width: 12vw;
    height: 12vw;
  }

  .bm {
    margin-top: 2.6vh;
    margin-left: 82vw;
  }

}

</style>