<template>
  <div class="resep">
    <div class="left">
      <div class="cent">
        <!-- <h1>
          О нас
        </h1> -->
        <p>
          Добро пожаловать в комфортный стоматологический кабинет, который напоминает визит к другу. <br>
          Наше гостеприимство и внимание не оставят вас равнодушными. <br>
        </p>
      </div>
    </div>
    <div class="right">
      <a-carousel autoplay effect="fade">
        <div><img class='ii' alt="" src="../assets/dk1.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/dk2.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/dk3.jpg" /></div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
import { Carousel } from 'ant-design-vue';

export default {
  name: 'Resep', 
  components: {
    ACarousel: Carousel,
  }
}
</script>

<style scoped>

.resep {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
  margin: 0 auto;
  background-color: #F7E0D6;
  height: 100vh;
}

.cent {
  width: 30vw;
  margin: 0 auto;
}

/* h1 {
  margin-top: 20vh;
  font-size: 3vw;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  color: #151515;
  font-weight: 300;
} */

p {
  line-height: 7vh;
  font-size: 1.5vw;
  font-family: Lato, sans-serif;
  color: #151515;
  font-weight: 300;
  margin-top: 25vh;
}

.right {
  height: 100vh;
}

.ii {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 100vh;
  /* line-height: 160px; */
  overflow: hidden;
}

/* .ant-carousel :deep(.slick-slide h3) {
  color: #fff;
} */

@media (max-width: 790px) {

  .resep {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    height: 70vh;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 10vh;
    margin-bottom: 10vh;
    font-weight: bold;
  }

  .cent {
    width: 80vw;
  }

  .ii {
    height: 40vh;
  }

  .ant-carousel :deep(.slick-slide) {
    height: 40vh;
  }

}

</style>