<template>
    <div class="phon">
        <a 
            href="tel:+78352373651"
        >
            <h3>
                Звонок
            </h3>
        </a> 
    </div>
</template>

<script>
export default {
    components: {
    }
}
</script>

<style scoped>

.phon,
a,
h3 {
    opacity: 0;
}

@media (max-width: 790px) {

    .phon {
        position: fixed;
        margin-top: 20vh;
        margin-left: 82vw;
        background-color: #6c9f67;
        width: 15vw;
        height: 15vw;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s ease;
        z-index: 10;
        opacity: 1;
        border: 1px solid white;
    }

    a {
        opacity: 1;
    }

    h3 {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 1.3vh;
        opacity: 1;
        text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
    }

}

</style>