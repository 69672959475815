<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="wrap">
    <div class="cont">
      <div class="left">
        <h1>Профилактическая гигиена</h1>
        <p>
          Профессиональная и индивидуальная гигиена полости рта является основным компонентом профилактики стоматологических заболеваний и основным показателем стоматологического здоровья. <br>
          Наша клиника предлагает профессиональную чистку зубов при помощи ультразвука. <br><br>
          Дефицит фтора и кальция в организме человека ведет к разрушению эмали зубов и дентина. Результат – повышение чувствительности зубов, склонность к развитию кариеса, заболевания периодонта. <br>
          В качестве профилактики предлагаем услугу фторирования зубов.
        </p>
        <br><br>
      </div>
        <a-carousel autoplay arrows effect="fade">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
              <left-circle-outlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <right-circle-outlined />
            </div>
          </template>
          <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
        </a-carousel>
    </div>
  </div>
  <div class="mr"></div>
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'AnyView',
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  margin: 0 auto;
  height: 70vh;
  margin-top: -7.5vh;
}

.cont {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.left {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;
}

h1 {
  font-size: 3vw;
}

p {
  font-size: 1.2vw;
  width: 95%;
}

.ii {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 100vh;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.mr {
  height: 30vh;
}

@media (max-width: 790px) {

  .wrap {
    height: 55vh;
    margin-top: 0;
  }

  .cont {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .left {
    height: 80vh;
  }

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 14px;
    width: 80%;
  }

  .ii {
    height: 40vh;
  }

  .ant-carousel :deep(.slick-slide) {
    height: 40vh;
  }

  .mr {
    height: 70vh;
  }

}

</style>

