import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServisView from '../views/ServisView.vue'
import NavView from '../views/NavView.vue'
import HygieneView from '../views/HygieneView.vue'
import TherapyView from '../views/TherapyView.vue'
import OrthopedicsView from '../views/OrthopedicsView.vue'
import DocView from '../views/DocView.vue'
import PriceView from '../views/PriceView.vue'
import ContactsView from '../views/ContactsView.vue'
import KemView from '../views/KemView.vue'
import KsvView from '../views/KsvView.vue'
import MeoView from '../views/MeoView.vue'
import LicView from '../views/LicView.vue'
import SezView from '../views/SezView.vue'

import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/servis',
    name: 'servis',
    component: ServisView
  },
  {
    path: '/nav',
    name: 'nav',
    component: NavView
  },
  {
    path: '/hygiene',
    name: 'hygiene',
    component: HygieneView
  },
  {
    path: '/therapy',
    name: 'therapy',
    component: TherapyView
  },
  {
    path: '/orthopedics',
    name: 'orthopedics',
    component: OrthopedicsView
  },
  {
    path: '/doc',
    name: 'doc',
    component: DocView
  },
  {
    path: '/price',
    name: 'price',
    component: PriceView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/kem',
    name: 'kem',
    component: KemView
  },
  {
    path: '/ksv',
    name: 'ksv',
    component: KsvView
  },
  {
    path: '/meo',
    name: 'meo',
    component: MeoView
  },
  {
    path: '/lic',
    name: 'lic',
    component: LicView
  },
  {
    path: '/sez',
    name: 'sez',
    component: SezView
  },

  {
    path: '/:pathMatch(.*)*', 
    name: 'notfound',
    component: NotFoundComponent
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { 
      top: 0, 
      behavior: 'smooth',
    }
  },
})

export default router
