<template>
    <h1>
        Данной страницы не существует, извините
    </h1>
    <div class="bu">
        <router-link to="/">
            <button>
                Перейти на главную страницу
            </button>
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

h1 {
    margin-top: 20vh;
    text-align: center;
}

.bu {
    display: flex;
    justify-content: center;
}

a {
    margin-top: 10vh;
    cursor: pointer;
}

</style>