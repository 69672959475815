<template>
  <Menu />
  <Zapis />
  <Phone />
  <div class="pri">
    <div class="cen">
      <div class="lef">
        <div class="mr"></div>
        <h1>Корнилова <br>
          Эльмира Мяксутовна</h1>
        <h2>врач стоматолог-терапевт</h2>
        <p>
          Окончила Чувашский Государственный Университет им. И.Н.Ульянова в 1993 году. <br>
          Диплом ЦВ №132935 по специальности «Стоматология». <br> 
          Сертификат №0152310539213 по специальности «Стоматология терапевтическая» от 27 ноября 2020 года. <br>
          Сертификат специалиста №1121242494894 «Организация здравоохранения и общественное здоровье» от 12 декабря 2020 года.
        </p>
      </div>
      <div class="rig">
        <img class='ii' alt="" src="../assets/doc1.jpg" />
      </div>
    </div>
  </div>
  <Map />
  <Foot />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Zapis from '@/components/Zapis.vue'
import Phone from '@/components/Phone.vue'
import Map from '@/components/Map.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'PriceView',
  components: {
    Menu,
    Zapis,
    Phone,
    Map,
    Foot,
  }
}
</script>

<style scoped>

.pri {
  background-color: #f5ee92;
  height: 100vh;
  margin-top: -7.5vh;
}

.cen {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
  margin: 0 auto;
}

.mr {
  height: 5vh;
}

h1 {
  font-size: 3vw;
  margin-left: 5vw;
}

h2 {
  font-size: 1.8vw;
  margin-left: 5vw;
}

p {
  margin-left: 5vw;
  margin-top: 5vh;
  font-size: 1.2vw;
  width: 40vw;
  line-height: 5vh;
}

.ii {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

@media (max-width: 790px) {

  .pri {
    height: auto;
    margin-top: 0;
  }

  .cen {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 17px;
    width: 80vw;
  }

}

</style>

